.site-wrapper {
  min-height: 100vh;
  position: relative;
}

.content-wrapper {
  padding-bottom: 76px;
}

main {
  margin: 0 auto;
  padding: 0 calc(var(--bs-gutter-x) * 2);
  // padding: 0 $bs-container-padding-x;
}

////////////
// Footer //
////////////

// Account for footer wrapping
@include media-breakpoint-up(xxs) {
  .content-wrapper {
    padding-bottom: 86px;
  }
}

.site-footer {
  bottom: 0;
  margin: 24px 0 0;
  min-height: 50px;
  position: relative;
  width: 100%;
}

///////////////////////
// Sticky Footer Fix //
///////////////////////

.body-page-short {
  height: 100%;

  .content-wrapper,
  .post-content {
    position: static;
  }

  .site-footer {
    position: absolute;
    width: 100%;
  }

  .content-wrapper {
    min-height: calc(100vh - 96px);
    padding-bottom: 56px;
  }

  .post-content {
    padding: 84px 2px 26px;
  }
}

@include media-breakpoint-up(xxs) {
  .body-page-short {
    .content-wrapper {
      padding-bottom: 32px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .body-page-short {
    .content-wrapper {
      padding-bottom: 0;
    }

    .post-content {
      margin: 0 0 38px;
      padding: 90px 0 26px;
    }
  }
}

@include media-breakpoint-up(md) {
  .body-page-short {
    .content-wrapper {
      padding-bottom: 0;
    }

    .post-content {
      padding: 92px 0 26px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .body-page-short {
    .post-content {
      padding: 96px 0 26px;
    }
  }
}

// Firefox & Chrome fix for no scrollbar
@media (min-width: 992px) and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
  .body-page-short {
    .content-wrapper {
      padding: 0 2px;
    }

    .post-header {
      padding: 10px 0 0 8px;
    }
  }
}


@include media-breakpoint-up(xl) {
  .body-page-short {
    .post-content {
      padding: 102px 0 26px;
    }
  }
}

.body-home {
  .content-wrapper {
    padding-bottom: 0;
  }
}

@include media-breakpoint-up(sm) {
  .body-home {
    .content-wrapper {
      padding-bottom: 0;
    }
  }
}

///////////////////////////
// End Sticky Footer Fix //
///////////////////////////

////////////////////////////////////////
// Headings & Anchor Links Scroll Fix //
////////////////////////////////////////

html {
  scroll-behavior: smooth;
}

// Prevent the page from overscrolling,
// so that nav menu doesn't cover content
//
// Not working for all About Headers. Will need to custom class it
:target:before {
  content: '';
  display: block;
  height: 76px;
  margin: -76px 0 0;
}

@include media-breakpoint-up(lg) {
  :target:before {
    height: 104px;
    margin: -104px 0 0;
  }
}

@include media-breakpoint-up(xl) {
  :target:before {
    height: 68px;
    margin: -68px 0 0;
  }
}

@include media-breakpoint-up(xxl) {
  :target:before {
    height: 74px;
    margin: -74px 0 0;
  }
}

////////////////////////////////////////////
// End Headings & Anchor Links Scroll Fix //
////////////////////////////////////////////

//////////////
// Homepage //
//////////////

.page-home .content-wrapper {
  padding-bottom: 0;
}

.page-section {
  margin: 1rem 0;
  padding: 0 2px;
}

.intro-container {
  margin: 1rem 0;
}

@include media-breakpoint-up(md) {
  .page-section {
    margin: 2rem auto;
    padding: 0;
  }

  .intro-container {
    margin: 1rem auto 2rem;
  }
}

@include media-breakpoint-up(lg) {
  .page-section {
    &.community {
      width: 96%;
    }
  }

  .intro-container {
    margin: 2rem auto;
  }
}

@include media-breakpoint-up(xxl) {
  .page-section {
    &.community {
      width: 90%;
    }
  }
}

.section-header {
  align-items: center;
  display: flex;
  flex-direction: column;
}

///////////////////
// Regular pages //
///////////////////

.post-header {
  background: $cwl-red;
  color: $white;
  left: 0;
  margin: 0;
  padding: 10px 0 0 12px;
  position: absolute;
  width: 100%;

  .container {
    padding-left: 0;
  }
}

@include media-breakpoint-up(sm) {
  .post-header {
    padding-left: 24px;
  }
}

.post-title {
  margin: 0 auto 1rem;
}

@include media-breakpoint-up(lg) {
  .post-title {
    font-size: 2.25rem;
  }
}

.post-content {
  padding: 0 2px;
  position: relative;
  top: 84px;
}

@include media-breakpoint-up(sm) {
  .post-content {
    padding: 0;
    top: 90px;
  }
}

@include media-breakpoint-up(md) {
  .post-content {
    top: 92px;
  }
}

@include media-breakpoint-up(lg) {
  .post-content {
    top: 96px;
  }
}

@include media-breakpoint-up(xl) {
  .post-content {
    top: 102px;
  }
}

