.social-bar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 5px;

  a,
  form {
    margin: 0 6px 6px;
  }

  a:first-of-type {
    margin: 0 6px 6px 0;
  }
}

.cwl-support {
  font-weight: 700;
  padding: 0 1rem 0 0;
}

@include media-breakpoint-up(sm) {
  .cwl-support {
    padding: 0;
  }
}

.paypal-btn {
  height: 30px;
  vertical-align: middle;
}
