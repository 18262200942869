body,
a {
  overflow-wrap: anywhere;
  word-break: normal;
}

h1,
.h1,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 1rem;
}

h2,
.h2 {
  color: $cwl-red;
  margin: 0 0 1.25rem;
}

.body-has-black-h2s,
.has-black-h2s {
  h2 {
    color: $black;
  }
}

.body-has-red-h3s,
.has-red-h3s {
  h3 {
    color: $cwl-red;
  }
}

figcaption {
  color: $grey-5;
  font-style: italic;
}

.cwl-red {
  color: $cwl-red;
}

.no-bold {
  font-weight: 400;
}

.heading__anchor-symbol {
  color: transparent;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: $cwl-red;
  }
}

.float-right {
  float: right;
}

// Change bullet points to red by default
li {
  &::marker {
    color: $cwl-red;
  }

  p {
    margin: 0;
  }
}

.getting-started,
.community {
  li {
    line-height: 1.5;
    margin: 0 0 0.5rem;

    &::marker {
      font-size: 1.125rem;
    }
  }
}
