.site-header-logo {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  overflow: hidden;

  img {
    height: 150px;
    margin: 1rem auto;
    max-width: 100%;
    width: auto;
  }
}

@include media-breakpoint-up(sm) {
  .site-header-logo {
    img {
      max-width: 80%;
    }
  }
}

@include media-breakpoint-up(md) {
  .site-header-logo {
    img {
      height: 200px;
      max-width: initial;
    }
  }
}

.cwl-announcement {
  background-color: $cwl-red;
  color: $white;
  padding: 0 1rem;
  text-align: center;

  h3 {
    font-size: 1.5rem;
    line-height: 2;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
}

@include media-breakpoint-up(md) {
  .cwl-announcement {
    h3 {
      font-size: 1.75rem;
    }
  }
}

