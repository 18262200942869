.mini-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;

  img {
    height: 100px;
    object-fit: scale-down;
    width: 160px;
  }
}

.mini-gallery-img-container {
  margin: 20px;
  max-height: 120px;
  overflow: hidden;
  max-width: 160px;
}

@include media-breakpoint-up(md) {
  .mini-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
  }

  .mini-gallery-img-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 20px;
    max-height: 100px;
  }
}

.min-gallery-img {
  max-width: 160px;
}

// Fixes for specific images
.mini-gallery {
  #manchester-escience-lab {
    height: 140px;
    width: 160px;
  }

  #elixir-container {
    overflow: visible;
  }

  #elixir {
    height: 80px;
    position: relative;
    top: 10px;
  }

  #msk-access {
    height: 100px;
    max-width: initial;
    position: relative;
    right: 20px;
    width: 200px;
  }

  #pubseq {
    height: auto;
    object-fit: cover;
    position: relative;
    width: 160px;
  }
}

@include media-breakpoint-up(sm) {
  .mini-gallery {
    #manchester-escience-lab-container {
      max-height: 130px;
      overflow: hidden;
    }
  }
}

