// Video Player
.youtube-container {
  max-height: 315px;
  max-width: 100%;
  padding: 15px 0;

  iframe,
  video {
    max-width: 100%;
  }
}

@include media-breakpoint-up(sm) {
  .youtube-container {
    height: auto;
    max-height: none;
    min-height: 315px;
  }
}

@include media-breakpoint-up(lg) {
  .youtube-container {
    margin: 0 0 0 36px;
    padding: 0 0 15px;
    width: 50%;
  }
}

@include media-breakpoint-up(xl) {
  .youtube-container {
    max-height: initial;
    min-height: initial;
    padding: 0 0 15px;
    width: 40%;
  }
}

// Address WebAIM Low Contrast Error
video {
  background: $black;
  color: $grey-f;
}

// Plyr
.plyr__poster {
  background-image: url('/assets/img/intro_video_poster.png');
  background-position: 50% 3%;
}

.title-bar {
  background: transparent;
  color: #fff;
  left: 0;
  padding: 10px 10px 10px 21px;
  position: relative;
  right: 0;
  top: 2.5px;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  z-index: 3;

  a {
    font-size: 16px;
  }
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, .75)) !important;
    padding: 5px 10px !important;
  }
}
