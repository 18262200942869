// Colors
$white: #fff;
$black: #000;
$black-013: #013243;
$black-4: #4a4a4a;
$black-6: #666;
$grey-4: #555;
$grey-5: #555;
$grey-7: #777;
$grey-8: #888;
$grey-c: #ccc;
$grey-d: #dee2e6;
$grey-e: #efefef;
$grey-f: #f2f2f2;
$salmon: #f5ecdf;

$cwl-menu-blue: #337ab7;
$cwl-red: #b5314c;
$link-blue-dark: #0264f7;
$link-blue: #0d6efd;
$orcid-green: #a6ce39;
$timeline-sep-grey: #5f6063;
$twitter-blue: #08a0e9;
$youtube-red: #c00;

$box-shadow--color: rgba(0, 0, 0, 0.1);

$nav--border-color: #e4e4e4;
$nav--border-color-2: rgba(0, 0, 0, 0.1);
$nav--bg-color: #f4f4f4;

$nav-height-mobile: 50px;
$nav-height-desktop: 40px;
$nav--brand-height-desktop: 56px;
$nav--logo-height-desktop: 50px;
$nav--logo-margin-desktop: 3px 0 3px 6px;
$nav--item-margin: 0.5rem;
$nav--item-padding: 0 1.25rem;
$nav--item-padding-lg-2: 0 0.5rem;

$nav-item-hover-color-desktop: #6c757d;
$nav-link-hover-color-desktop: rgba(0, 0, 0, 0.8);

$nav-link--color: rgba(0, 0, 0, 0.55);
$nav-link--hover-color: $cwl-red;

$left-nav-title--color: $black;
$left-nav-toggle--background-color: $grey-e;
$left-nav-toggle--border-color: $black-6;
$left-nav-toggle--box-shadow-color: $grey-8;
$left-nav-toggle--focus--border-color: $grey-7;
$left-nav-toggle--focus--box-shadow-color: $grey-7;

$skip-link--box-shadow-color: rgba(0, 0, 0, 0.6);

$table-bg-grey: $grey-f;
$table-border-light: #dee2e6;
$table--cell-striped-background: $table-bg-grey;
$table--cell-border-bottom: $grey-d;
$table--cell-border-bottom-alt: $grey-c;
$table--link-color: $link-blue-dark;

$footer-bg-color: $cwl-red;
$footer-link-old-color: #005285;
$footer-link-old-hover-color: #22527c;

// Override default bootstrap grid breakpoints
$grid-breakpoints: (
  xs: 0,
  xxs: 425px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  lg-2: 1024px,
  xl: 1200px,
  xl-13: 1330px,
  xxl: 1400px
);
// scss-docs-end grid-breakpoints

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px,
//   xxl: 1200px,
//   // xl12: 1200px
// );
