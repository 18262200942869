.anchors-center-fix {
  .heading__anchor-symbol {
    margin: 0 0 0 9px;
    position: absolute;
  }
}

.no-underline {
  text-decoration: none;
}

.black-text {
  color: $black;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.w-480 {
  width: 480px;
}

@include media-breakpoint-up(lg) {
  .mobile-br  {
    display: none;
  }
}
