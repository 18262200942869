.site-footer {
  background-color: $footer-bg-color;
  padding: 10px 15px 0;

  p,
  a {
    color: $white;
  }
}

.footer-text {
  margin: 0 auto;
  text-align: center;
}
